import React from 'react';
import ReactDOM from 'react-dom/client';
import './Sides.css';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

function ContactSide() {

    const position = {lat: 53.54, lng: 10};
    const myApiKey = "AIzaSyAAbUp5lrzQbXekfRc7PpKchiBNsoNh22k";

    return (
        <div id='ContactSide'>
            <div id='Map'>
                Strona w budowie ...
            </div>
            <div id='ContactData'>
                <p><b>Adres: </b> ul. Jana Padareskiego 5/10, 76-200 Słupsk</p>
                <p><b>Godziny otwarcia: </b> poniedziałek - piątek 9.00-16.00</p>
                <p><b>Numer telefonu: </b> +48 786 917 647</p>
                <p><b>Adres e-mail: </b> biuro@idladomu.pl</p>
                <p><b>Social media: </b> <a href='https://www.facebook.com/profile.php?id=61553730187757' target='_blank'>Facebook</a></p>
            </div>
        </div>
    )
}

export default ContactSide;