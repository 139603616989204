import React from 'react';
import logomain from './Images/logomainwhite.svg';
import './App.css';
import MainSide from './MainSide.js';
import ContactSide from './ContactSide.js';
import ServicesSide from './ServicesSide.js';
import AboutUsSide from './AboutUsSide.js';
import Article from './Article.js';

function App() {

  const [side, changeSide] = React.useState(0);
  const [article, changeArticle] = React.useState(null);

  function menuOptionClicked(option) {
    if (side !== option) {
      if (option === 0) changeSide(0);
      if (option === 1) changeSide(1);
      if (option === 2) changeSide(2);
      if (option === 3) changeSide(3);
    }
  }

  function openArticel(params){
    changeArticle(params);
    changeSide(4);
  }

  function renderMainSection() {
    if (side === 0) return <MainSide openArticle={openArticel}/>
    if (side === 1) return <AboutUsSide />
    if (side === 2) return <ServicesSide openArticle={openArticel}/>
    if (side === 3) return <ContactSide />
    if (side === 4) return <Article />
  }

  return (
    <div>
      <div id="headder">
        <div id="logo"><img src={logomain} alt="Logo" /></div>
        <div id="menu">
          <p className="menuOption" onClick={() => { menuOptionClicked(0) }}>Strona Głowna</p>
          <p className="menuOption" onClick={() => { menuOptionClicked(1) }}>O nas</p>
          <p className="menuOption" onClick={() => { menuOptionClicked(2) }}>Usługi</p>
          <p className="menuOption" onClick={() => { menuOptionClicked(3) }}>Kontakt</p>
        </div>
      </div>
      <div id="mainSection">
        {renderMainSection()}
      </div>
      <div id="footer"><p className="footerText">Copyright © 2024 Oskar Gumowski. All rights reserved.</p></div>
    </div>
  );
}

export default App;

