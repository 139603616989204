import img1 from './Images/post1.jpg';
import img2 from './Images/post2.jpg';
import img3 from './Images/post3.jpg';

import AuditIco from './Images/AuditIco.png';
import SCHEIco from './Images/SCHEIco.png';
import OZCIco from './Images/OZCIco.png';
import OZEIco from './Images/OZEIco.png';
import LAFIco from './Images/LAFIco.png';
import CarbonIco from './Images/CarbonIco.png';
import ThermoIco from './Images/ThermoIco.png';


function post(tittle, text, postPreview, img){
    this.tittle = tittle;
    this.text = text;
    this.postPreview = postPreview;
    this.img = img;
}

let blogs = [];
let services=[];

// Blogi

blogs.push(new post("Badania termograficzne", "Opis", "Badania termograficzne (termowizja) to specyficzny rodzaj ekspertyzy obrazujący straty ciepła z budynku. Dzięki analizie budynku z wykorzystaniem kamery termowizyjnej możliwe jest zlokalizowanie tzw. mostków termicznych w konstrukcji budynku. Mostki termiczne są obszarami konstrukcji o niskim oporze przewodzenia ciepła, co prowadzi do dużych strat energii, zawilgocenia wnętrz i powstawaniu pleśni.", img3));
blogs.push(new post("Cyfrowy model budynku", "Opis", "BIM to cyfrowa technologia umożliwiająca trójwymiarowe modelowanie budynku z uwzględnieniem jego właściwości fizycznych i funkcjonalnych, stosowana w różnych dziedzinach architektury i inżynierii. Parametryczny model budynku wykorzystujemy do wykonywania złożonych obliczeń cieplnych. Na rysunku cyfrowy model domu, dla którego wystawiliśmy kolejne Świadectwo Charakterystyki Energetycznej 🙂", img2));
blogs.push(new post("Ekspertyzy cieplne", "Opis", "Program TERMO to program dofinansowania do termomodernizacji skierowany do osób fizycznych, wspólnot i spółdzielni mieszkaniowych oraz jednostek samorządu terytorialnego. W ramach programu przyznawane są różnorodne formy wsparcia w postaci grantów i premii. Premia termomodernizacyjna obniża kwotę kredytu zaciągniętego na potrzeby termomodernizacji od 26% do 31% kosztów całkowitych przedsięwzięcia. Z premii mogą skorzystać m.in. wspólnoty i spółdzielnie mieszkaniowe, towarzystwa budownictwa społecznego i właściciele domków jednorodzinnych. Nasza firma wykona dla Państwa niezbędne pomiary i przygotuje audyt energetyczny niezbędny do pozyskania premii termomodernizacyjnej. Zachęcamy Państwa do skorzystania z naszej oferty.", img1));


// Usługi
services.push(new post("Audyt Energetyczny", "Opis", "Opracowanie techniczne określające profil zużycia energii w budynku oraz rozwiązania techniczne mające na celu ograniczenie tego zużycia, a także ekonomiczne uzasadnienie wskazanych prac. Audyt energetyczny jest niezbędny do uzyskania dofinansowania do termomodernizacji lub poprawy efektywności energetycznej w państwowych programach wsparcia takich jak Program Czyste Powietrze, Program TERMO, Kredyt Ekologiczny.", AuditIco));
services.push(new post("Charakterystyka Energetyczna", "Opis", "Opracowanie określające wielkość zapotrzebowania na energię dla budynku projektowanego lub w stanie istniejącym. Projektowa Charakterystyka Energetyczna jest potrzebna do uzyskania pozwolenia na budowę, podczas gdy Świadectwo Charakterystyki Energetycznej jest dokumentem wymaganym do uzyskania pozwolenia na użytkowanie budynku, a także podczas sprzedaży bądź najmie nieruchomości, obniżenia oprocentowania na kredy hipoteczny i w celu uzyskania dofinansowania do pompy ciepła w programie Moje Ciepło.", SCHEIco));
services.push(new post("Obliczenia OZC", "Opis", "Ekspertyza określające zapotrzebowanie na moc grzewczą budynku, umożliwiająca dobór mocy urządzenia grzewczego (np. kotła lub pompy ciepła).", OZCIco));
services.push(new post("Dobór mocy OZE", "Opis", "Korzystając z aktualnych danych meteorologicznych jesteśmy w stanie oszacować uzysk z instalacji fotowoltaicznych w rejonie Państwa inwestycji. Znając zapotrzebowanie na energię elektryczną Państwa obiektu dobieramy moc projektowanej instalacji fotowoltaicznej. ", OZEIco));
services.push(new post("Badania termograficzne", "Opis", "Opracowanie będące wynikiem badania budynku z użyciem kamery termowizyjnej, umożliwiającego zlokalizowanie mostków termicznych i ocenę jakości istniejącej izolacji cieplnej.", ThermoIco));
services.push(new post("Moc szczytowa i współczynniki wyrównawcze LAF", "Opis", "Wykonujemy również obliczenia zapotrzebowania na moc szczytową w budynkach wielorodzinnych i obliczamy współczynniki korekcyjne LAF niezbędne do sprawiedliwego rozdziału kosztów za ogrzewanie  pomiędzy lokale wyposażone w podzielniki ciepła.", LAFIco));
services.push(new post("Ślad węglowy", "Opis", "Obliczamy ślad węglowy przedsiębiorstw.", CarbonIco));

export {blogs, services};



