import React from 'react';
import ReactDOM from 'react-dom/client';
import './Sides.css';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import {services} from './todisplay.js';
import Post from './Post.js';

function ServicesSide(props) {

    function renderPosts() {
        return (services.map((post, index) => {
            return (
                <Post postPreview={post.postPreview} img={post.img} tittle={post.tittle} openArticle={props.openArticle}/>
            )
        }));
    }

    return (
        <div id="ServicesSide">
            {renderPosts()}
        </div>
    );
}

export default ServicesSide;