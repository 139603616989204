import React from 'react';
import ReactDOM from 'react-dom/client';
import './Sides.css';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

function AboutUsSide() {

    return (
        <div id='AboutUsSide'>
            Strona w budowie ....
        </div>
    )
}

export default AboutUsSide;