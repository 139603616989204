import React from 'react';
import ReactDOM from 'react-dom/client';
import './Article.css';

function Article(props) {

    return (
        <div className='Article'>
            <div className='ArticleImg'></div>
            <div className="ArticleTextSection">
                <div><p className='ArticleTittle'>Strona w budowie ...</p></div>
                <div><p className="ArticleText"></p></div>
            </div>
        </div>
    )
}

export default Article;
